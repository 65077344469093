import { ERole } from "../enum/role.enum";
import { IPath } from "../interfaces/paths.interface";

export const limitedPaths: IPath[] = [
    {
        id: 'users',
        label: 'Users',
        absolutePath: '/dashboard/users',
        relativePath: 'users',
        roles: [ERole.ADMIN, ERole.SUPER_MERCHANT],
    },
    {
        id: 'mid-creation',
        label: 'Mid Creation',
        absolutePath: '/dashboard/mid-management/mid/creation',
        relativePath: 'mid/creation',
        roles: [ERole.ADMIN, ERole.SUPER_MERCHANT],
    },
    {
        id: 'group-creation',
        label: 'Group Creation',
        absolutePath: '/dashboard/mid-management/group/creation',
        relativePath: 'group/creation',
        roles: [ERole.ADMIN, ERole.SUPER_MERCHANT],
    },
    {
        id: 'mid-management',
        label: 'Mid Management',
        absolutePath: '/dashboard/mid-management',
        relativePath: 'mid-management',
        roles: [ERole.ADMIN, ERole.SUPER_MERCHANT, ERole.MERCHANT],
    }
]
